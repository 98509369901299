import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import Image from "gatsby-image"
import Form from "../components/form"
import {FaPrint, FaPhone, FaTimes} from "react-icons/fa"
import $ from "jquery"


export const query = graphql`
    query pagequery ($slug: String){
        sanityPages(slug: {current: {eq: $slug}}) {
            pagetitle
            slug {
                current
            }
            buttontext
            offertext
            phonenumber
            formtext
            formtitle
        }
        sanityCompanyInfo {
            companyname
            primarycolor{
                hex
            }
            secondarycolor{
                hex
            }
            accentcolor{
                hex
            }
            logo {
              asset {
                fluid(maxWidth: 700) {
                  ...GatsbySanityImageFluid
                }
              }
            }
        }
    }
`

function openForm (){
  $(".column2").toggleClass("expanded");
  $('body').toggleClass("formExpanded");
}
function closeForm (){
  $(".column2").toggleClass("expanded");
  $('body').toggleClass("formExpanded");
}
function printCoupon() {
  if(typeof window !== 'undefined'){
    window.print();
}
}

const IndexPage = ( { data }) => (
  <Layout>
    <Helmet>
        <title>{data.sanityPages.title}</title>
        <script type="text/javascript">{`
                vs_account_id      = "fwABAV8xWVVwrwBG";
              `}</script>
              <script type="text/javascript" src="https://rw1.calls.net/euinc/number-changer.js"></script>
    </Helmet>
    <div className="pageWrapper">
      <div className="container">
        <div className="two_columns">
          <div className="column column1">
            <div className="column-inner" >
                <Image fluid={data.sanityCompanyInfo.logo.asset.fluid} className="companyLogo" style={{width: 210}}/>
                <span className="cta" href="#" style={{backgroundColor: data.sanityCompanyInfo.primarycolor.hex}}>Today Only</span>
                <span className="offer">
                <h1>{data.sanityPages.offertext}</h1>
                <span className="restrictions">*Restrictions may apply. Call office for details.</span>
                </span>
                <a className="print" href="#" onClick={printCoupon}><FaPrint /> Print</a>
              <footer>
                <span>&#169; Acree Plumbing & Air | Marketing by VitalStorm Marketing Inc.</span>
                
              </footer>
            </div>
          </div>
          <div className="column column2">
            <div className="column-inner">
            <a className="phonenumber" href={"tel:" + data.sanityPages.phonenumber} style={{backgroundColor: data.sanityCompanyInfo.accentcolor.hex}} ><FaPhone /> <span id="number_rewrite">{data.sanityPages.phonenumber}</span></a>
              <span className="closeForm" onClick={closeForm}><FaTimes /></span>
              <h2>Claim this limited time offer now!</h2>
              <p>Fill out the form below to claim your exclusive offer.</p>
              <p>We'll reach out to schedule your service appointment.</p>
                <Form />
            </div>
          </div>
        </div>
      </div>
    </div>
    <span className="claimOffer" onClick={openForm} >Claim Offer</span>
    <a className="mobilePhonenumber" href={"tel:" + data.sanityPages.phonenumber} style={{backgroundColor: data.sanityCompanyInfo.accentcolor.hex, color: data.sanityCompanyInfo.secondarycolor}} ><FaPhone style={{transform: 'rotate(90deg)', color: data.sanityCompanyInfo.secondarycolor}}/> <span style={{color: data.sanityCompanyInfo.secondarycolor}} id="number_rewrite">{data.sanityPages.phonenumber}</span></a>
  </Layout>
)
 
export default IndexPage
